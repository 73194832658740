



















































import { Component, Vue } from 'vue-property-decorator';
import CustomerModel from '@/models/Customer.model';
import CustomersRepository from '@/repository/modules/customers.repository';
import { setterDate } from '@/plugins/utils';

@Component
export default class ListCustomers extends Vue {
  customers: CustomerModel[] = [];
  page = 1;
  limit = 50;
  total_results = 0;
  total_pages = 1;
  loading = false;
  search_email = '';

  async mounted(): Promise<void> {
    await this.getCustomers(this.page);
  }

  async getCustomers(page: number, query: { email_to_find: string | null } = { email_to_find: null }): Promise<void> {
    this.loading = true;
    const { customers, total_results, total_pages } = await CustomersRepository.getListCustomers({
      query: query.email_to_find,
      page,
      limit: this.limit,
    });
    this.customers = customers;
    this.total_results = total_results ?? 0;
    this.total_pages = total_pages;
    this.loading = false;
  }

  changePage(page: number) {
    this.getCustomers(page, this.queryBuilder());
  }

  findListCustomers() {
    this.page = 1;
    this.getCustomers(1, this.queryBuilder());
  }

  queryBuilder() {
    const obj: any = {};
    if (this.search_email != '') {
      obj.email_to_find = this.search_email;
    }
    return obj;
  }

  setDate = (dater: Date) => setterDate(dater);
}
